import { boolean, object, string } from "yup";

export const ebookFormValidationSchema = object({
  name: string().required("Please enter your firstname"),
  email: string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  business_type: string().required(),
  join_mailing_list: boolean(),
});
