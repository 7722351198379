// extracted by mini-css-extract-plugin
export var GetEbook = "GetEbook-module--GetEbook--ypcHn";
export var GetEbook_container = "GetEbook-module--GetEbook_container--E+zfU";
export var GetEbook_content = "GetEbook-module--GetEbook_content--g7C3e";
export var GetEbook_content_desc = "GetEbook-module--GetEbook_content_desc--Yfzgy";
export var GetEbook_content_form = "GetEbook-module--GetEbook_content_form--+IYPV";
export var GetEbook_content_form_button = "GetEbook-module--GetEbook_content_form_button--3j-ZP";
export var GetEbook_content_form_checkbox = "GetEbook-module--GetEbook_content_form_checkbox---S6Ij";
export var GetEbook_content_form_group = "GetEbook-module--GetEbook_content_form_group--bLyCp";
export var GetEbook_content_form_group_input = "GetEbook-module--GetEbook_content_form_group_input--v5jYf";
export var GetEbook_content_form_select = "GetEbook-module--GetEbook_content_form_select--uizkK";
export var GetEbook_content_title = "GetEbook-module--GetEbook_content_title--cFRLr";
export var GetEbook_image = "GetEbook-module--GetEbook_image--V4heX";
export var GetEbook_image_lg = "GetEbook-module--GetEbook_image_lg--RCq1v";
export var GetEbook_image_sm = "GetEbook-module--GetEbook_image_sm--C0wL2";