import React from "react";
import { Resources } from "components/design-system";
import { CallToAction, Features, GetEbook } from "components/ebook";
import SEO from "components/SEO";
import { FEATURES } from "data/pages/ebook";
import pagesMeta from "data/pagesMeta";
import { graphql, PageProps } from "gatsby";

const index: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.ebook} />
      <GetEbook />
      <Features data={FEATURES} />
      <CallToAction />
      <Resources
        title="Read our business guide series"
        items={pageData.resources}
      />
    </>
  );
};

export const query = graphql`
  query EbookPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "home" } } }
    ) {
      edges {
        node {
          frontmatter {
            resources {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default index;
