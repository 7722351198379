export const FEATURES = [
  {
    title: "The processes of getting started",
    description:
      "The first section of this book contains chapters that teach you how to write a successful business plan, how to raise capital for your company, and why you should register your company.",
  },
  {
    title: "Running your business",
    description:
      "This section discusses social media, effective customer service, business tax, and business accounting tips.",
  },
  {
    title: "Growing your business",
    description:
      "This section was written for businesses in the growth stage, and it covers everything from opening your business to international markets to simple growth tactics, hiring, and business credit.",
  },
  {
    title: "Safe-keeping your business",
    description:
      "Finally, the chapters in this section will teach you how to protect your business from fraud, how to avoid business bankruptcy, and much more.",
  },
];

export const EBOOK_BUSINESS_OPTIONS = [
  "No, I don't have a business",
  "Yes, I have a registered business",
  "Yes, but I need help registering my business",
];
