import {
  Button,
  ButtonVariant,
  CheckBox,
  notifyError,
  notifySuccess,
  Select,
  TextInput,
} from "brass-ui-kit";
import { Formik, FormikHelpers } from "formik";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { ebookFormValidationSchema } from "../validator";
import { postRequestEbook } from "functions";

import * as styles from "./GetEbook.module.scss";
import { EBOOK_BUSINESS_OPTIONS } from "data/pages/ebook";

const GetEbook: React.FC = () => {
  const initialValues = {
    name: "",
    email: "",
    business_type: "",
    join_mailing_list: false,
  };

  const handleFormSubmit = async (
    values: typeof initialValues,
    helpers: FormikHelpers<typeof initialValues>
  ) => {
    try {
      const response = await postRequestEbook(values);

      if (response.data) {
        notifySuccess(response.data.message);
        helpers.resetForm();
      } else {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      notifyError(
        err.response ? err.response.data.error.description : err.message
      );
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <section className={styles.GetEbook} id="getEbook">
      <div className={styles.GetEbook_container}>
        <div className={styles.GetEbook_content}>
          <h1 className={styles.GetEbook_content_title}>
            Download our free business guide e-book
          </h1>
          <p className={styles.GetEbook_content_desc}>
            We have published a free e-book titled ‘The SME Guide’ to provide
            small and medium business owners with resources that can help you
            learn how to start, run, grow, and safe-keep their business
            effectively in Nigeria.
          </p>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={ebookFormValidationSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              isValid,
              dirty,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={styles.GetEbook_content_form}
              >
                <div className={styles.GetEbook_content_form_group}>
                  <TextInput
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="name"
                    className={styles.GetEbook_content_form_group_input}
                    label="First name"
                    errorMessage={touched.name ? errors.name : undefined}
                  />
                  <TextInput
                    value={values.email}
                    className={styles.GetEbook_content_form_group_input}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    label="Email address"
                    errorMessage={touched.email ? errors.email : undefined}
                  />
                </div>
                <Select
                  label="Are you a business owner?"
                  value={values.business_type}
                  options={EBOOK_BUSINESS_OPTIONS.map((item) => {
                    return {
                      value: item,
                    };
                  })}
                  labelField="value"
                  placeholder="Select which of these best describes you"
                  onChange={(value) =>
                    setFieldValue("business_type", value.value)
                  }
                  className={styles.GetEbook_content_form_select}
                />
                <CheckBox
                  checked={values.join_mailing_list}
                  label="I would like to receive marketing emails and be added to Brass’ mailing list."
                  className={styles.GetEbook_content_form_checkbox}
                  id="join_mailing_list"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("join_mailing_list", evt.target.checked);
                  }}
                />
                <Button
                  variant={ButtonVariant.Primary}
                  type="submit"
                  disabled={!dirty || !isValid}
                  loading={isSubmitting}
                  className={styles.GetEbook_content_form_button}
                >
                  Get it for free
                </Button>
              </form>
            )}
          </Formik>
        </div>
        <div className={styles.GetEbook_image_lg}>
          <StaticImage
            src="../../../assets/media/images/ebook/handbook.png"
            alt="ebook"
            loading="eager"
          />
        </div>
        <div className={styles.GetEbook_image_sm}>
          <StaticImage
            src="../../../assets/media/images/ebook/handbook-sm.png"
            alt="ebook"
            loading="eager"
            as="span"
          />
        </div>
      </div>
    </section>
  );
};

export default GetEbook;
