// extracted by mini-css-extract-plugin
export var Features = "Features-module--Features--PPoJC";
export var Features_content = "Features-module--Features_content--CQ-kj";
export var Features_content_container = "Features-module--Features_content_container--J1X3R";
export var Features_content_container_vline = "Features-module--Features_content_container_vline--5Kdst";
export var Features_content_item = "Features-module--Features_content_item--qinMf";
export var Features_content_item_details = "Features-module--Features_content_item_details--bjBJj";
export var Features_content_item_details_description = "Features-module--Features_content_item_details_description--KMBuB";
export var Features_content_item_details_title = "Features-module--Features_content_item_details_title--ZqMVW";
export var Features_content_item_index = "Features-module--Features_content_item_index--t+963";
export var Features_header = "Features-module--Features_header--2YPUI";
export var Features_header_title = "Features-module--Features_header_title--m17Bo";