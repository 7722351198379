import React from "react";
import * as styles from "./Features.module.scss";

type Feature = {
  title: string;
  description: string;
};

interface FeatureProps {
  data: Array<Feature>;
}

const Features: React.FC<FeatureProps> = ({ data }) => {
  return (
    <section className={styles.Features}>
      <header className={styles.Features_header}>
        <h1 className={styles.Features_header_title}>
          In this e-book, you'll learn:
        </h1>
      </header>
      <ul className={styles.Features_content}>
        <div className={styles.Features_content_container}>
          {data.map((_, index) => (
            <div
              className={styles.Features_content_container_vline}
              key={index}
            />
          ))}
        </div>
        {data.map(({ title, description }, index) => (
          <li key={index} className={styles.Features_content_item}>
            <div className={styles.Features_content_item_index}>
              {index + 1}
            </div>

            <div className={styles.Features_content_item_details}>
              <h3 className={styles.Features_content_item_details_title}>
                {title}
              </h3>
              <p className={styles.Features_content_item_details_description}>
                {description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Features;
